


































import Vue from "vue";
import { mapActions, mapState,mapMutations, mapGetters } from "vuex";
import { getCleanName } from "../rename-censor";
// import CharacterRoom from "./CharacterRoom.vue";
// import CharacterRoomArt from "./CharacterRoomArt.vue";
import BackgroundItem from "./BackgroundItem.vue";
// import CharacterRoomArt from "./CharacterRoomArt.vue";

export default Vue.extend({

  data() {
    return {
      waitingResults: false,
      resultsAvailable: false,
      fightResults: null,
      error: null,
      room: null,
    };
  },
  components: { BackgroundItem },
  props: ["request", "handleFight"],
  computed: {
    ...mapState(["characters", "careerModeRooms"]),
    ...mapGetters(["getCharacterName"]),
  },

  methods: {
    ...mapActions(["fetchCharacters", "fight", "getRoom"]),
    ...mapMutations(['setIsInCombat']),
    getCleanCharacterName(id: number) {
      return getCleanName(this.getCharacterName(id));
    },
  },

  async mounted() {
    console.log('lai ne', this.request);
    if (this.request.heroId) {
      await this.fetchCharacters([this.request.heroId]);
    }
  },
});
