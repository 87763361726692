















































































































































































































import { BModal } from "bootstrap-vue";
import Vue from "vue";
import { mapGetters, mapState, mapActions } from "vuex";
import BackgroundItem from "../components/BackgroundItem.vue";
import WeaponGrid from "@/components/smart/WeaponGrid.vue";
import CharacterRoom from "@/components/CharacterRoom.vue";
import RoomRequest from "@/components/RoomRequest.vue";
import WeaponBackground from '@/components/WeaponSelect.vue';
import CombatResults from "@/components/CombatResults.vue";
import CharacterSelectList from "../components/smart/CharacterSelectList.vue";
// import CustomModal from "@/components/CustomModal.vue";
// import InforBar from '@/components/smart/InforBar.vue';

interface IData {
  characterId?: number;
  weaponId?: number;
  selectedCharacter?: any;
  selectedWeapon?: any;
  currentTab: "career_mode" | "request";
  matchReward: number;
  totalDeposit: number;
  fetchRoomInterval: any;
  fetchRequestInterval: any;
  waitingResults: any,
  resultsAvailable: any,
  fightResults: any,
  error: any,
  room: any,
  errorMessage: string;
  isVisibleModal: boolean;
  showHeroPicker: boolean;
}

export default Vue.extend({
  components: {
    BackgroundItem,
    WeaponGrid,
    CharacterRoom,
    RoomRequest,
    WeaponBackground,
    CombatResults,
    CharacterSelectList
    // CustomModal
    // InforBar
  },
  data() {
    return {
      characterId: undefined,
      weaponId: undefined,
      selectedCharacter: null,
      selectedWeapon: null,
      currentTab: "career_mode",
      matchReward: 0,
      totalDeposit: 0,
      fetchRoomInterval: null,
      fetchRequestInterval: null,
      waitingResults: false,
      resultsAvailable: false,
      fightResults: null,
      error: null,
      room: null,
      errorMessage: '',
      isVisibleModal: false,
      showHeroPicker: false
    } as IData;
  },

  computed: {
    ...mapState([
      "characters",
      "currentCharacterId",
      "careerModeRooms",
      "careerModeRequest"
    ]),
    ...mapGetters(["currentCharacter", "getCharacterName", "ownWeapons", "ownCharacters"]),
    // ...mapMutations(["setIsInCombat"]),

    character(): any {
      if (!this.currentCharacter) {
        return {
          id: null,
          name: "???",
          level: -1,
          experience: -1
        };
      }

      const c = this.currentCharacter;

      return {
        id: c.id,
        name: this.getCharacterName(c.id),
        level: c.level,
        experience: c.xp
      };
    },
    updateResults() {
      // @ts-ignore
      return [this.fightResults, this.error];
    },
  },
  watch: {
    characterId(val) {
      // @ts-ignore
      this.selectedCharacter = this.characters[val];
    },
    weaponId(val) {
      // @ts-ignore
      this.selectedWeapon = this.ownWeapons.find(
        (w: any) => !!w && w.id === val
      );
    },
    async updateResults([fightResults, error]) {
      // @ts-ignore
      this.resultsAvailable = fightResults !== null;
      // @ts-ignore
      this.waitingResults = fightResults === null && error === null;
      // this.setIsInCombat(this.waitingResults);
      if(fightResults) {
        console.log('test gifhgt', fightResults);
      }
      // @ts-ignore
      if (this.resultsAvailable && error === null) this.$bvModal.show('fightResultsModal');
    },
  },
  methods: {
    ...mapActions(["createCareerRoom", "getCareerRooms", "getRequests", "fight"]),
    openHeroPicker() {
      (this.$refs["hero-career-mode-selector"] as BModal).show();
      // this.showHeroPicker = true;
    },
    openWeaponPicker() {
      (this.$refs["weapon-career-mode-selector"] as BModal).show();
    },
    handleCreateRoom() {
      // @ts-ignore
      console.log('show me the answer', this.characterId);
      // @ts-ignore
      if(!this.selectedCharacter || !this.selectedWeapon) {
        // @ts-ignore
        this.errorMessage = 'Please select weapon and hero!';
        // @ts-ignore
        this.$bvModal.show('error-request-fight');
      }
      else {
        // @ts-ignore
        this.createCareerRoom({
        // @ts-ignore
          character: this.characterId,
          // @ts-ignore
          weapon: this.weaponId,
          // @ts-ignore
          matchReward: this.matchReward,
          // @ts-ignore
          totalDeposit: this.totalDeposit
        });
      }

    },
    async handleFight(roomId: any,requestId: any) {
      // @ts-ignore
      this.waitingResults = true;
      // @ts-ignore
      this.fightResults = null;
      // @ts-ignore
      this.error = null;
      // this.setIsInCombat(this.waitingResults);
      try{
        // @ts-ignore
        const results = await this.fight({
          roomId, requestId
        });
        console.log('gi v ta', results);
        // @ts-ignore
        this.fightResults=results;
        // @ts-ignore
        this.error=null;
      } catch (e: any) {
        console.error(e);
        // @ts-ignore
        this.error = e.message;
      }
    },
    filterCareerModeRequest () {
      return this.careerModeRequest.filter((item: any) => !item.done);
    },
  },
  async mounted() {
    // @ts-ignore
    this.fetchRoomInterval = setInterval(async () => {
      // @ts-ignore
      await this.getCareerRooms();
    }, 3000);
    // @ts-ignore
    this.fetchRequestInterval = setInterval(async () => {
      // @ts-ignore
      await this.getRequests();
    }, 3000);
  }
});
