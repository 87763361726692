


























import Vue from "vue";
import CharacterRoomArt from "../components/CharacterRoomArt.vue";
import { mapActions, mapState } from "vuex";
import Web3 from "web3";

export default Vue.extend({
  props: ["characterId", "room", "selectedWeaponId", "selectedCharacterId", "isRequest"],
  components: {
    CharacterRoomArt,
  },
  methods: {
    ...mapActions(["fetchCharacters", "requestFight"]),
    handleRequestFight() {
      //@ts-ignore
      this.requestFight({
        roomId: this.room.id,
        weaponId: this.selectedWeaponId,
        characterId: this.selectedCharacterId,
      });
    },
  },
  computed: {
    ...mapState(["characters"]),
    matchReward() {
      return Web3.utils.fromWei(this.room.matchReward, "ether");
    },
  },
  async mounted() {
    //@ts-ignore
    await this.fetchCharacters([this.characterId]);
  },
});
